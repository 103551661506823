import FAQ from '../components/_faq';

class AccordionTabs {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.tabsEls = this.root.querySelectorAll('[data-accordion-tabs-tab]');
        this.cardEls = this.root.querySelectorAll('[data-accordion-tabs-card]');
        this.accordionEls = this.root.querySelectorAll('[data-accordion]');
        this.faqInstances = [];

        return true;
    }

    init() {
        this.setTabs();
    }

    setTabs() {
        if (!this.tabsEls) return;

        this.onTab = this.onTabClick.bind(this);
        this.tabsEls.forEach(item => {
            item.addEventListener('click', this.onTab);
        });
    }

    setAccordionEls() {
        if (!this.accordionEls) return;

        this.accordionEls.forEach(item => {
            this.faqInstances.push(new FAQ(item));
        });
    }

    onTabClick(event) {
        const { currentTarget } = event;
        const index = currentTarget.getAttribute('data-accordion-tabs-tab');
        const card = this.root.querySelector(
            `[data-accordion-tabs-card="${index}"]`
        );

        this.resetItems(this.tabsEls);
        this.resetItems(this.cardEls);

        this.setActiveItem(currentTarget);
        this.setActiveItem(card);

        this.setAccordionEls();
    }

    setActiveItem(item) {
        if (!item) return;
        item.classList.add('-active');
    }

    resetItems(items) {
        items.forEach(item => item.classList.remove('-active'));
    }
}

function initAccordionTabs() {
    const sections = document.querySelectorAll('[data-accordion-tabs]');
    if (!sections) return;

    sections.forEach(item => new AccordionTabs(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initAccordionTabs();
} else {
    document.addEventListener('DOMContentLoaded', initAccordionTabs);
}
